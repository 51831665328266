import urljoin from "url-join";
import axios from "axios";
import axiosRetry from "axios-retry";

const PROD_URL = "https://peekaph.one/";
const HOSTNAME_TO_BASE_URL = {
	// production
	"peek-a-phone-website-ads.web.app": PROD_URL,
	"peek-a-phone-website-ads.firebaseapp.com": PROD_URL,
	"tannerslist.com": PROD_URL,
	"www.tannerslist.com": PROD_URL,
};
if (process.env.NODE_ENV !== "production") {
	HOSTNAME_TO_BASE_URL[
		window.location.hostname
	] = `http://${window.location.hostname}:9080/`;
}
const REQUEST_TIMEOUT_MS = 10 * 1000;

const client = axios.create();
axiosRetry(client, {
	retries: 5,
	retryDelay: axiosRetry.exponentialDelay,
	retryCondition: axiosRetry.isNetworkOrIdempotentRequestError,
	shouldResetTimeout: true,
});

export function applyForAd(uid) {
	return _apiCall(`ad/${uid}/apply`, "put");
}

function _apiCall(url, method, options) {
	const { data } = options || {};

	return axios.request({
		url: urljoin(_getApiUrl(), url),
		method,
		timeout: REQUEST_TIMEOUT_MS,
		withCredentials: true,
		data,
	});
}

function _getApiUrl() {
	const baseUrl = HOSTNAME_TO_BASE_URL[window.location.hostname] || PROD_URL;
	return urljoin(baseUrl, "/api/websites/ads");
}
