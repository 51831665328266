import { applyForAd } from "../gameApi";
import styles from "./AdApplyButton.module.css";

import React from "react";

export default function AdApplyButton({ text, replyUid }) {
	function clickHandler(evt) {
		evt.preventDefault();
		const response = window.prompt("Enter your email address");
		if (response != null && response.trim().length > 0) {
			applyForAd(replyUid)
				.then(() => window.alert("Thank you for replying to the ad!"))
				.catch(() =>
					window.alert(
						"Error while replying to the ad, please try again."
					)
				);
		}
	}

	return (
		<button className={styles.button} onClick={clickHandler}>
			{text}
		</button>
	);
}
