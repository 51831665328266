import Layout from "../components/layout";
import SEO from "../components/seo";
import AdApplyButton from "../components/AdApplyButton";
import MarkdownContent from "../components/MarkdownContent";
import { InlineLoader } from "../components/Loaders";

import React from "react";

export default function Ad({ pageContext: { ad, replyEmail, replyUid } }) {
    const { id, title, body, showReplyButton } = ad;

    if (replyEmail === undefined) {
        replyEmail = `reply-${id}@stuartslist.com`;
    }

    return (
        <Layout>
            <SEO title={title} />
            <h1>{title}</h1>
            <MarkdownContent markdown={body} />
            <p>
                {"Reply by email: "}
                {replyEmail ? (
                    <a
                        href={`mailto:${replyEmail}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {replyEmail}
                    </a>
                ) : (
                    <InlineLoader />
                )}
                {showReplyButton && replyUid ? (
                    <>
                        {", or click: "}
                        <AdApplyButton text="REPLY" replyUid={replyUid} />
                        {"."}
                    </>
                ) : null}
            </p>
        </Layout>
    );
}
