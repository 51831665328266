import "./Loaders.css";

import React from "react";

export function InlineLoader() {
	return (
		<span className="InlineLoader">
			<span className="InlineLoader-child"></span>
			<span className="InlineLoader-child"></span>
			<span className="InlineLoader-child"></span>
			<span className="InlineLoader-child"></span>
		</span>
	);
}
